@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline-0 {
    -webkit-text-stroke: 0px #ffffff;
  }
  .font-outline {
    -webkit-text-stroke: 0.5px #0036f1;
  }

  .nav-link {
    @apply bg-sky-100 rounded-md text-main bg-transparent dark:bg-transparent dark:text-slate-600;
  }
  .nav-link:hover {
    @apply text-sky-800 dark:text-slate-300;
  }
  .nav-link-active {
    @apply font-semibold text-sky-800 rounded-md
          dark:text-slate-100;
  }
}
